import React, { Component } from "react";
import { withRouter } from "react-router";
import Logo from "../../images/favicon.png";
import "./App.css";

class Footer extends Component {
  render() {
    const { history } = this.props;
    return (
      <div className="container-fluid" id="footer" style={{ padding: 0 }}>
        <div
          className="footer-wrapper"
          style={{
            margin: "0 auto",
          }}
        >
          <div className="footer-container">
            <div className="logo-style top">
              <img
                src={Logo}
                alt=""
                onClick={() => history.push("/")}
                style={{ cursor: "pointer" }}
              />
              <div className="logo-name visibleNot">
                <span style={{ color: "dodgerblue" }}>Deeve</span>
                <span style={{ fontWeight: "lighter" }}>Fit</span>
              </div>
            </div>

            <div
              className="bottom"
              style={{
                display: "flex",
                justifyContent: "space-between",
                textAlign: "left",
                flex: 1,
              }}
            >
              <div style={{ display: "flex", flexDirection: "column" }}>
                <a href="/">Home</a>
                <a href="#howitworks">How it works</a>
                <a href="">
                  <span onClick={() => history.push("/getstarted")}>
                    Membership
                  </span>
                </a>
              </div>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <a href="#services">Services</a>
                <a href="#faq">FAQ</a>

                <a href="https://blog.deevefit.com">Blog</a>
              </div>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <ul>
                  <li>
                    <a href="https://www.facebook.com/deevefit/">
                      <i
                        style={{ color: "#008cff", padding: "0.5em 0.7em" }}
                        className="fab fa-facebook-f"
                      ></i>
                      Facebook
                    </a>
                  </li>
                  <li>
                    <a href="https://www.instagram.com/deevefit_virtual_fitness/">
                      <i
                        className="fab fa-instagram"
                        style={{ color: "#e22828", padding: "0.5em" }}
                      ></i>
                      Instagram
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div
              style={{
                alignSelf: "flex-end",
                position: "relative",
              }}
              className="side"
            >
              <img
                src={Logo}
                alt="deevefit_logo"
                onClick={() => history.push("/")}
                style={{
                  cursor: "pointer",
                  maxHeight: "20rem",
                  opacity: 0.2,
                  position: "relative",
                }}
              />
              <button
                className="react-btn footer-btn btn btn-primary"
                onClick={() => history.push("/getstarted")}
              >
                <h1>JOIN TODAY</h1>
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Footer);
