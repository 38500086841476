import React, { Component } from "react";
import Async from "react-code-splitting";
import { withRouter } from "react-router";
import { Icon, Modal } from "semantic-ui-react";
// import Banner from "../Banner/Banner";
import WeightLossPage from "../../pages/WeightLossPage/WeightLossPage";
import Testimonials from "../Testimonials/Testimonials";
import "./App.css";
// import Goal from './Goal';
import Features from "./Features";
import Footer from "./Footer";
import Hero from "./Hero";
import HowItWorks from "./HowItWorks";
import Membershiptep2 from "./Membershipstep2";
import Nav from "./Nav";
import WhatWeOffer from "./WhatWeOffer";
const FAQ = () => <Async load={import("./FAQ")} />;
// const Membership = (props) => (
//   <Async load={import("./Membership")} componentProps={props} />
// );
const Terms = () => <Async load={import("./Terms")} />;
const PrivacyPolicy = () => <Async load={import("./PrivacyPolicy")} />;
const Registration = (props) => (
  <Async load={import("../register/Registration")} componentProps={props} />
);

class Home extends Component {
  constructor(props) {
    super(props);
    this.handleLogin = this.handleLogin.bind(this);
    this.state = {
      hero: true,
      what: true,
      faq: false,
      goal: true,
      services: true,
      features: true,
      programs: true,
      how: true,
      benefits: true,
      price: false,
      Membership: false,
      ref: false,
      home: true,
      terms: false,
      privacy: false,
      register: false,
      testimonials: true,
      open: true,
      status: {
        home: true,
        services: false,
        how: false,
        features: false,
        programs: false,
        price: false,
        faq: false,
        testimonials: false,
        getstarted: false,
      },
    };
  }

  componentDidMount() {
    if (localStorage.getItem("isAuthenticated")) {
      this.props.history.replace("/dashboard");
    }
    if (this.props.register) this.register("Silver Plan");
    if (this.props.terms) this.showPage("terms");
    else if (this.props.privacy) this.showPage("privacy");
    else if (this.props.price) {
      this.setState({
        hero: false,
        faq: false,
        goal: false,
        services: false,
        features: false,
        programs: false,
        how: false,
        benefits: false,
        price: true,
        Membership: true,
        ref: false,
        terms: false,
        privacy: false,
        testimonials: false,
        status: {
          home: false,
          services: false,
          how: false,
          features: false,
          programs: false,
          price: false,
          faq: false,
          getstarted: true,
          testimonials: false,
        },
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.price && prevState.home) {
      if (this.props.location.pathname === "/register") {
        this.setState({
          home: false,
          price: false,
          register: true,
          Membership: false,
        });
      }
    }
    if (
      prevProps.location.pathname !== this.props.location.pathname &&
      this.state.register
    ) {
      if (this.props.location.pathname === "/getstarted") {
        this.setState({
          home: true,
          price: true,
          register: false,
          Membership: true,
        });
      }
    }
  }

  handleLogin(v) {
    this.props.onLog(v);
  }

  register = (plan) => {
    this.setState({
      hero: false,
      faq: false,
      goal: false,
      services: false,
      testimonials: false,
      features: false,
      programs: false,
      how: false,
      benefits: false,
      price: false,
      Membership: false,
      ref: false,
      home: false,
      terms: false,
      privacy: false,
      register: true,
      plan: plan,
      status: {
        home: false,
        services: false,
        how: false,
        features: false,
        programs: false,
        price: false,
        faq: false,
        getstarted: false,
        testimonials: false,
      },
    });
  };

  showPage = (page) => {
    this.setState({
      hero: false,
      faq: false,
      goal: false,
      services: false,
      features: false,
      programs: false,
      how: false,
      benefits: false,
      price: false,
      Membership: false,
      ref: false,
      terms: false,
      privacy: false,
      testimonials: false,
    });
    switch (page) {
      case "terms":
        this.setState({ terms: true });
        this.setState({
          status: {
            home: false,
            services: false,
            testimonials: false,
            how: false,
            features: false,
            programs: false,
            price: false,
            faq: false,
            getstarted: false,
          },
        });
        window.scrollTo(0, 0);
        break;
      case "privacy":
        this.setState({ privacy: true });
        this.setState({
          status: {
            home: false,
            services: false,
            testimonials: false,
            how: false,
            features: false,
            programs: false,
            price: false,
            faq: false,
            getstarted: false,
          },
        });
        window.scrollTo(0, 0);
        break;
      case "faq":
        this.setState({ faq: true });
        this.setState({
          status: {
            home: false,
            services: false,
            testimonials: false,
            how: false,
            features: false,
            programs: false,
            price: false,
            faq: true,
            getstarted: false,
          },
        });
        window.scrollTo(0, 0);
        break;
      case "goal":
        this.setState({
          hero: true,
          goal: true,
          services: true,
          features: true,
          programs: true,
          how: true,
          benefits: true,
          testimonials: true,
        });
        this.setState({
          status: {
            home: true,
            services: false,
            testimonials: false,
            how: false,
            features: false,
            programs: false,
            price: false,
            faq: false,
            getstarted: false,
          },
        });
        setTimeout(() => {
          window.location = "/#goal";
        }, 100);
        break;
      case "features":
        this.setState({
          hero: true,
          goal: true,
          services: true,
          features: true,
          programs: true,
          how: true,
          benefits: true,
          testimonials: true,
        });
        this.setState({
          status: {
            home: false,
            services: false,
            testimonials: false,
            how: false,
            features: true,
            programs: false,
            price: false,
            faq: false,
            getstarted: false,
          },
        });
        setTimeout(() => {
          window.location = "/#features";
        }, 100);
        break;
      case "programs":
        this.setState({
          hero: true,
          goal: true,
          services: true,
          features: true,
          programs: true,
          how: true,
          benefits: true,
          testimonials: true,
        });
        this.setState({
          status: {
            home: false,
            services: false,
            testimonials: false,
            how: false,
            features: false,
            programs: true,
            price: false,
            faq: false,
            getstarted: false,
          },
        });
        setTimeout(() => {
          window.location = "/#programs";
        }, 100);
        break;
      default:
        break;
    }
  };
  closeConfigShow = (closeOnEscape, closeOnDimmerClick) => () => {
    this.setState({ closeOnEscape, closeOnDimmerClick, open: true });
  };
  close = () => {
    this.setState({ open: false });
    const { history } = this.props;
    history.push("/");
  };
  render() {
    var status = this.state.status;
    const { open, closeOnEscape, closeOnDimmerClick } = this.state;
    const { history } = this.props;

    return (
      <div className="web-wrapper" id="home">
        {/* {
       !this.state.price && !this.state.register && <Banner />
      } */}
        {!this.state.register && (
          <Nav
            onSelectNav={(nav) => {
              this.setState(nav);
            }}
            onLogin={this.handleLogin}
            status={status}
          />
        )}

        {this.state.hero && <Hero />}
        {/* {this.state.goal && <Goal />} */}
        {this.state.services && <WhatWeOffer />}
        {this.state.how && <HowItWorks />}
        {this.state.features && <Features />}
        {this.state.testimonials && <Testimonials />}
        {/* {this.state.benefits && <Benefits />} */}
        {this.state.faq && <FAQ />}
        {this.state.price && <Membershiptep2 register={this.register} />}
        {this.state.terms && <Terms />}
        {this.state.privacy && <PrivacyPolicy />}
        {this.state.register && <Registration plan={this.state.plan} />}
        {!this.state.register && <Footer showPage={this.showPage} />}
        {window.location.pathname === "/weight-loss-challenge" ? (
          <Modal
            open={open}
            closeOnEscape={closeOnEscape}
            closeOnDimmerClick={closeOnDimmerClick}
            onClose={this.close}
            dimmer="blurring"
          >
            <Icon name="close" onClick={this.close} />
            <Modal.Content>
              <WeightLossPage history={history} />
            </Modal.Content>
          </Modal>
        ) : null}
      </div>
    );
  }
}

export default withRouter(Home);
