import React, { Component } from "react";
import { withRouter } from "react-router";
import { Icon, Modal } from "semantic-ui-react";
import FreeModalSignUp from "../FreeModalSignUp/FreeModalSignUp";
import "./App.css";
class Hero extends Component {
  constructor(props) {
    super(props);
    this.state = {
      //    message: 'd'
      open: false,
    };
  }

  componentDidMount() {
    //this.intervalId = setInterval(this.changeMessage, 160)
  }

  componentWillUnmount() {
    //clearInterval(this.intervalId);
  }

  // changeMessage = () => {
  //     let message1="diabetes",message2="high risk of diabetes";
  //     let message = this.state.message ;
  //     //console.log(this.state.message);
  //     if(message[0]=='d')
  //     {
  //         if(message.length<message1.length)
  //         {
  //             message=message+message1[message.length];
  //         }
  //         else{
  //             message="h";
  //         }

  //     }
  //     else
  //     {
  //         if(message.length<message2.length)
  //         {
  //             message=message+message2[message.length];
  //         }
  //         else{
  //             message="d";
  //         }
  //     }
  //     this.setState({
  //         message: message
  //     })
  // }

  render() {
    const { open } = this.state;
    const { history } = this.props;
    return (
      <div
        className="container-fluid"
        id="hero"
        style={{
          padding: 0,
          borderRight: "10px solid #00b1ff",
          position: "relative",
        }}
      >
        <div>
          <img
            src="assets/images/Home Bg.png"
            alt="hero-img"
            className="img-fluid w-100 "
          />
          <div className="custom-card ">
            <div className="card-body">
              <h1 className="card-title hd">
                1-ON-1 PERSONAL AND SMALL GROUP DIGITAL TRAINING
              </h1>

              <p
                className="card-text"
                style={{ marginBottom: "20px ", color: "hsl(0, 0%, 40%)" }}
              >
                Get the most personalized training experience,ever!!
                <br /> At home, at work, at the gym, or on the go.
              </p>

              <div style={{ marginTop: "5px" }}>
                <Modal
                  size="small"
                  open={open}
                  style={{
                    position: "relative",
                    overflowY: "scroll",
                    height: "fit-content",
                    top: "10%",
                  }}
                  trigger={
                    <button
                      className="hero-btn  btn "
                      style={{
                        backgroundColor: "#06b100",
                        color: "#fff",
                        padding: "6px 18px",
                      }}
                      onClick={() => this.setState({ open: true })}
                    >
                      SIGN UP FREE
                    </button>
                  }
                >
                  <Icon
                    name="close"
                    onClick={() => this.setState({ open: false })}
                  />
                  <Modal.Content scrolling>
                    <FreeModalSignUp />
                  </Modal.Content>
                </Modal>

                <button
                  type="button"
                  className="hero-btn  btn btn-outline-primary"
                  style={{
                    marginLeft: "1.5em",
                    padding: "6px 18px",
                    fontWeight: 500,
                    backgroundColor: "#1f84fc",
                    color: "#fff",
                  }}
                  onClick={() => history.push("/login")}
                >
                  LOGIN
                </button>

                <p style={{ marginTop: "0.75em", fontSize: "0.85rem" }}>
                  No Credit Card Required | No Registration Fee | Cancel Anytime
                </p>
              </div>
            </div>
          </div>

          <div className="hero-sidebar">
            <ul>
              <li>
                <a href="https://www.facebook.com/deevefit/">
                  <i
                    style={{ color: "#fff" }}
                    className="fab fa-facebook-f"
                  ></i>
                </a>
              </li>
              <li>
                <a href="https://www.instagram.com/deevefit_virtual_fitness/">
                  <i className="fab fa-instagram"></i>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Hero);
