import React from 'react';
import {Button} from 'semantic-ui-react';
import './App.css'
class NotFound extends React.Component{
  render(){
    return(
      <div>
        <h1 className="nf">404</h1>
        <h4 className="nft">OOPS, SORRY WE CAN NOT FIND THAT PAGE!</h4>
        <h6 className="nfm">Either something went wrong or the page does not exist anymore</h6>
        <div className="nfb"><Button><a href="/">Home Page</a></Button></div>
      </div>
    )
  }
}
export default NotFound;
