import React, { Component } from "react";
import "./App.css";

class HowItWorks extends Component {
  render() {
    return (
      <div className="container-fluid" id="howitworks">
        <h1
          className="hd"
          style={{ fontSize: "3.5rem", margin: "0.5em 0", marginTop: "1.25em" }}
        >
          HOW IT WORKS
        </h1>
        <div className="work-container">
          <div>
            <img
              src="assets/images/How it works 1.png"
              alt="signup_at_deevefit"
              // style={{ height: "15em" }}
            />
            <h3>Sign up/Login</h3>
          </div>
          <div>
            <img
              src="assets/images/How it works 2.png"
              alt="choose_deevefit_session"
              // style={{ height: "15em" }}
            />
            <h3>Choose the Session that will work for schedule</h3>
          </div>
          <div>
            <img
              src="assets/images/How it works 3.png"
              alt="get_trained _at_deevefit"
              // style={{ height: "15em" }}
            />
            <h3>Turn on your webcam and follow your trainer</h3>
          </div>
        </div>
      </div>
    );
  }
}

export default HowItWorks;
