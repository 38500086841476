export const firebaseConfig = {
  apiKey: "AIzaSyCU1jsNdKz5qlhHTIVm6tz6ZVAKp0fDw8M",
  authDomain: "deevefit-prd.firebaseapp.com",
  databaseURL: "https://deevefit-prd.firebaseio.com",
  projectId: "deevefit-prd",
  storageBucket: "deevefit-prd.appspot.com",
  messagingSenderId: "498464791917",
  appId: "1:498464791917:web:7acd3aa0350c4dad8f8e64"
};

export const messagingId =
   'BGcfeeFZDZ45Zgz700KgEwFnfwV182qoHKx3jj2B1w0etygqlrFdJG4TWahYTDPdsKrXYyAvIc9o903JR3mo5HU';


export const serverKey =
   'AAAAdA7RHW0:APA91bGvunEo-AJylV3SV8p02Z6JWcjKhzLbhlVtSV1OniDDgxUAp9GPJTXk23yzsiLpvSHPNlLaL5Sm7cpKBemZ3rag6e-_O_UNzHhCgjGXpNjv9rXWSCWTRk_fBfYs44lR6cqkjsru';

export const USDAapikey = "P50MqdzIJFxB7WUS3w4mLG0jfWeh0D6AuXfpRbif";
