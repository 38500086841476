import React from "react";
import { Form, Loader, Message } from "semantic-ui-react";
import { fireAuth, fireDatabase } from "../../utils/fire";

class FreeModalSignUp extends React.Component {
  state = {
    email: "",
    name: "",
    password: "",
    message: "",
    credits: 1,
    plan: { planID: "2weeks_free", plan: "free trial" },
  };
  validateInput(email, password) {
    if (email === "" || password === "") {
      this.setState({
        message: "Both email & password have to be entered",
      });
      return false;
    } else if (this.state.plan === "") {
      this.setState({
        message: "You must select a plan",
      });
      return false;
    } else {
      const regex =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (!regex.test(email)) {
        this.setState({
          message: "Please make sure it is a valid email",
        });
        return false;
      } else if (password !== "") {
        const minLen = password.length;
        if (minLen < 6) {
          this.setState({
            message: "Password needs atleast 6 characters",
          });
          return false;
        } else {
          this.setState({
            message: "Checking for authentication",
          });
          return true;
        }
      }
    }
  }
  check = async (event) => {
    var uid;
    if (this.validateInput(this.state.email, this.state.password)) {
      try {
        const emailMatch = await fireAuth.fetchSignInMethodsForEmail(
          this.state.email
        );
        const doesExist = (await emailMatch.length) === 0 ? false : true;
        if (doesExist) {
          this.setState({
            message: "Email ID is already in use by another user.",
          });
        } else {
          this.setState({ message: "" });
          await fireAuth
            .createUserWithEmailAndPassword(
              this.state.email,
              this.state.password
            )
            .then(async (firebaseUser) => {
              uid = fireAuth.currentUser.uid;
              await fireDatabase.ref("USERS/" + uid).set({
                uid: uid,
                displayName: this.state.name,
                email: this.state.email,
                category: "student",
                credits: this.state.credits,
                plan: this.state.plan.plan,
                planID: this.state.plan.planID,
                isAgree: 0,
              });
              await fireDatabase
                .ref(this.state.plan.plan + "/" + uid)
                .set({ email: this.state.email });
              var countRef = await fireDatabase
                .ref("Stats/" + this.state.plan.plan)
                .once("value");
              var count = (await countRef.val()) + 1;
              await fireDatabase
                .ref("Stats/" + this.state.plan.plan)
                .set(count);
              this.setState({ message: "Signup Successful" }, () => {
                if (count) {
                  window.location = "/login";
                }
              });
            });
        }
      } catch (err) {
        this.setState({ message: err.message });
      }
    }
  };
  render() {
    return (
      <div>
        {this.state.message && (
          <div
            className="col-10 col-sm-8 col-md-6 col-lg-5"
            style={{
              marginLeft: "5%",
              marginTop: "1em",
              marginBottom: "1em",
              paddingLeft: "3em",
            }}
          >
            <Message>
              {this.state.message === "Checking for authentication" ? (
                <Loader active inline="centered" />
              ) : (
                this.state.message
              )}
            </Message>
          </div>
        )}
        <div
          className="col col-sm-8 col-md-6 "
          style={{
            marginLeft: "5%",
            paddingLeft: "3em",
          }}
        >
          <Form>
            <Form.Input
              required
              fluid
              id="username"
              icon="user"
              label="Name"
              iconPosition="left"
              placeholder="Enter Name"
              type="test"
              value={this.state.name}
              onChange={(event) => this.setState({ name: event.target.value })}
            />
            <Form.Input
              required
              fluid
              label="Email"
              id="useremail"
              icon="mail"
              iconPosition="left"
              placeholder="Enter Email"
              type="email"
              value={this.state.email}
              onChange={(event) => this.setState({ email: event.target.value })}
            />
            <Form.Input
              required
              fluid
              icon="lock"
              label="Password"
              id="userpassword"
              iconPosition="left"
              type="password"
              placeholder="Enter Password"
              value={this.state.password}
              onChange={(event) =>
                this.setState({ password: event.target.value })
              }
            />
            <div style={{ fontSize: "12px", paddingTop: "12px" }}>
              By clicking CONTINUE, you are confirming that you are atleast 18
              years of age and agree to our{" "}
              <a href="/privacy">Privacy policy</a> and{" "}
              <a href="/terms">Terms of Use</a>
            </div>
            <br />
            <button
              onClick={this.check}
              style={{ margin: "auto", display: "block" }}
            >
              Continue
            </button>
          </Form>
        </div>
      </div>
    );
  }
}

export default FreeModalSignUp;
