import React from "react";
import { deviceType } from "react-device-detect";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
const items = [
  {
    src: "vaishnavi_transformation.PNG",
    link: "https://www.instagram.com/p/CLCWy4Sl8oN/?utm_source=ig_web_copy_link",
  },
  {
    src: "Pradyumn.jpeg",
    link: "https://www.instagram.com/p/CKT3dPpA4cz/?utm_source=ig_web_copy_link",
  },
  {
    src: "Vijay.jpeg",
    link: "https://www.instagram.com/p/CJysidwFtip/?utm_source=ig_web_copy_link",
  },
  {
    src: "Sunitha_Ravi_2.jpeg",
    link: "https://www.instagram.com/p/CJeK1FXsL7b/?utm_source=ig_web_copy_link",
  },
  {
    src: "Sunitha_Ravi_1.jpeg",

    link: "https://www.instagram.com/p/CJeK1FXsL7b/?utm_source=ig_web_copy_link",
  },

  {
    src: "divya_transformation.jpeg",
    link: "https://www.instagram.com/p/CI6EviCHiO_/?utm_source=ig_web_copy_link",
  },
  {
    src: "Ravi transformation.jpeg",

    link: "https://www.instagram.com/p/CIyejsXh32a/?utm_source=ig_web_copy_link",
  },
  {
    src: "Aravinthan chandrasekaran.jpeg",

    link: "https://www.instagram.com/p/CIuQMcQMz45/?utm_source=ig_web_copy_link",
  },
  {
    src: "Rajkumar weightloss.jpeg",
    link: "https://www.instagram.com/p/CIi24XpnuK4/?utm_source=ig_web_copy_link",
  },
  {
    src: "Vijay.jpeg",
    link: "https://www.instagram.com/p/CJYv9UplYi8/?utm_source=ig_web_copy_link",
  },
];
const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1300 },
    items: 3,
  },

  tablet: {
    breakpoint: { max: 1300, min: 900 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 900, min: 0 },
    items: 1,
  },
};
class Testimonials extends React.Component {
  render() {
    //console.log("Test rendered");
    return (
      <div id="testimonials">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12 benifitstext">
              <h1
                className="hd"
                style={{
                  fontSize: "3.5rem",
                  marginTop: "1.85em ",
                  textAlign: "center",
                }}
              >
                TESTIMONIALS
              </h1>
            </div>
          </div>
        </div>
        <div className="container-fluid" id="why">
          <Carousel
            swipeable={false}
            draggable={false}
            showDots={false}
            responsive={responsive}
            ssr={true} // means to render carousel on server-side.
            infinite={false}
            keyBoardControl={true}
            customTransition="all .5"
            transitionDuration={500}
            containerClass="carousel-container"
            // removeArrowOnDeviceType={["tablet", "mobile"]}
            renderButtonGroupOutside={true}
            deviceType={deviceType}
          >
            {items.map(({ src, link }, index) => (
              <div key={index} draggable={false} style={{ margin: "0 20px" }}>
                <a href={link} target="_blank" rel="noreferrer">
                  <img
                    className="d-block w-100"
                    src={`assets/images/${src}`}
                    alt="deevefit_testimonial"
                  />
                </a>
              </div>
            ))}
          </Carousel>
        </div>
      </div>
    );
  }
}
export default Testimonials;
