import React from "react";
import Async from "react-code-splitting";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Home from "./components/static/Home";
// import PageNotFound from "./components/404page/PageNotFound";
import NotFound from "./NotFound";
import Login from "./pages/login/LoginContainer";

const App = () => <Async load={import("./App")} />;
// const NotFound = () => <Async load={import("./NotFound")} />;
ReactDOM.render(
  <BrowserRouter>
    <Switch>
      <Route path="/login" component={Login} />
      <Route path="/register" render={() => <Home register={true} />} />
      <Route path="/getstarted" render={() => <Home price={true} />} />
      <Route path="/terms" render={() => <Home terms={true} />} />
      <Route path="/privacy" render={() => <Home privacy={true} />} />
      <Route path="/dashboard" component={App} />
      <Route path="/" component={() => <Home reasons={true} />} />
      <Route component={() => <NotFound />} />
      {/* // <Route path="*" component={() => <PageNotFound reasons={true} />} /> */}
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);
