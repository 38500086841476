import React, { Component } from "react";
import "./ConfirmPackage.css";

export default class ConfirmPackage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      date: new Date(),
    };
  }

  render() {
    // let newDate = new Date();
    return (
      <div className="checkoutContainerBox">
        <div className="headerTextDiv">
          <h3 className="headerTextDiv__heading">Invoice</h3>
        </div>
        <div className="body">
          <div className="bodyTableHead bodyTable">
            <div className="bodyLeft">Date</div>
            <div className="bodyCenter">Item/Pack</div>
            <div className="bodyRight">Ammount</div>
          </div>
          <div className="bodyTableContent bodyTable">
            <div className="bodyLeft">30th July</div>
            <div className="bodyCenter">
              {this.props.packageDetails.heading}
            </div>
            <div className="bodyRight">{this.props.packageDetails.price}</div>
          </div>
          <div className="bodyTablePrice bodyTable">
            <div className="bodyLeft"></div>
            <div className="bodyCenter"></div>
            <div className="bodyRight Totalprice">
              {this.props.packageDetails.price}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
