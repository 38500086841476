import { FormControl, Typography } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import Checkbox from "@material-ui/core/Checkbox";
import { green } from "@material-ui/core/colors";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { withStyles } from "@material-ui/core/styles";
import React, { Component, Fragment } from "react";
import { withRouter } from "react-router";
import "./MembershipPage.css";
import {
  FitnessPersonal,
  FitnessPoints,
  FitnessSmallGroup,
  Nutrition,
  NutritionFitness,
  NutritionFitnessPoints,
  NutritionPoints,
  Yoga,
} from "../../utils/PlanData";

import ConfirmPackage from "./ConfirmPackage.js";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";
const defaultValue = 1;

const Tabs = [
  {
    id: 1,
    title: "Nutrition",
  },
  {
    id: 2,
    title: "Fitness",
  },
  {
    id: 3,
    title: "Nutrition + Fitness",
  },
  {
    id: 4,
    title: "Yoga",
  },
];
class MembershipPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tabNumber: 1,
      frequencyDialog: false,
      weeklySessionValue: "2 sessions/week",
      checkout: false,
      selectedProgramDetails: null,
      planInfo: null,
      isPersonalTraining: false,
      Fitness: FitnessSmallGroup || [],
      checked: -1,
    };
  }

  handleChange = (index) => {
    if (this.state.checked === index) {
      this.setState({
        checked: -1,
      });
    } else {
      this.setState({
        checked: index,
      });
    }
  };

  handleLetsToThis = (details, index) => {
    this.setState({ selectedProgramDetails: details, index });

    if (this.state.checked === index && details.combo_plan) {
      details = {
        ...details,
        id: details.combo_plan,
        heading: details.combo_plan_heading,
        current_price: details.combo_price,
        checked: true,
      };
    } else {
      details = { ...details, checked: false };
    }
    this.props.history.push({
      pathname: this.props.userObject ? "/dashboard/checkout" : "/register",
      state: {
        plan: details,
      },
    });
  };

  componentDidMount() {
    this.setState({ checked: this.state.tabNumber === 1 ? defaultValue : -1 });
  }

  renderCheckout = () => {
    return (
      <div className="confirmPackageContainer">
        <ConfirmPackage packageDetails={this.state.selectedProgramDetails} />
        <button
          className="continueToPayment"
          onClick={() => {
            this.props.history.push({
              pathname: this.props.userObject
                ? "/dashboard/checkout"
                : "/register",
              state: {
                plan: this.state.planInfo,
              },
            });
          }}
        >
          Continue To Payment
        </button>
      </div>
    );
  };

  handleFitnessCheckBox = () => {
    if (!this.state.isPersonalTraining) {
      this.setState({
        Fitness: FitnessPersonal,
      });
    } else {
      this.setState({
        Fitness: FitnessSmallGroup,
      });
    }
    this.setState({
      isPersonalTraining: !this.state.isPersonalTraining,
    });
  };

  render() {
    // console.log(this.state);
    return (
      <Fragment>
        {/* for checkout */}
        {this.state.checkout && this.renderCheckout()}
        <div className="tab__containerWrapper">
          <ChevronLeftIcon
            fontSize="large"
            className="membership__leftIcon"
            onClick={() => {
              this.setState({
                tabNumber:
                  this.state.tabNumber === 1 ? 1 : this.state.tabNumber - 1,
              });
            }}
          />
          <div className="tab__containerMobile">
            {Tabs.slice(this.state.tabNumber - 1, this.state.tabNumber).map(
              ({ id, title }) => (
                <div
                  key={id}
                  className={`tab__wrapper ${
                    this.state.tabNumber === id && "active"
                  } `}
                  onClick={() => this.setState({ tabNumber: id })}
                >
                  <p>{title}</p>
                </div>
              )
            )}
          </div>
          <div className="tab__container">
            {Tabs.map(({ id, title }) => (
              <div
                key={id}
                className={`tab__wrapper ${
                  this.state.tabNumber === id && "active"
                } `}
                onClick={() => this.setState({ tabNumber: id })}
              >
                <p>{title}</p>
              </div>
            ))}
          </div>
          <ChevronRightIcon
            fontSize="large"
            className="membership__rightIcon"
            onClick={() => {
              this.setState({
                tabNumber:
                  this.state.tabNumber === Tabs.length
                    ? Tabs.length
                    : this.state.tabNumber + 1,
              });
            }}
          />
        </div>
        {/* Points */}
        {this.state.tabNumber === 1 && (
          <Fragment>
            <div
              style={{
                display: "grid",
                justifyContent: "center",
              }}
            >
              {NutritionPoints.map((text, index) => (
                <div className="membership__point" key={index}>
                  <img src="/assets/images/check.svg" alt="check-icon"></img>
                  <p>{text}</p>
                </div>
              ))}
            </div>

            <div className="membership__cardContainer">
              <Fragment>
                {Nutrition.map((planInfo, index) => {
                  return (
                    <Card className="membership__card" key={planInfo.id}>
                      <div style={{ textAlign: "center" }}>
                        <div>
                          <span>
                            <h1>${planInfo?.current_price}</h1>
                            {planInfo?.original_price && (
                              <span className="membership__linethrough">
                                ${planInfo?.original_price}
                              </span>
                            )}
                          </span>
                        </div>
                        <h2>
                          {planInfo?.months}
                          <span> months</span>
                        </h2>
                        <h3
                          style={{
                            margin: 0,
                            padding: 0,
                            color: "hsl(0, 0%, 60%)",
                            lineHeight: 1,
                            visibility: planInfo?.discount
                              ? "visible"
                              : "hidden",
                          }}
                        >
                          {planInfo?.discount}% off
                        </h3>
                      </div>
                      <div>
                        <FormControlLabel
                          style={{ margin: "1rem 2rem" }}
                          control={
                            <GreenCheckbox
                              checked={
                                this.state.checked === index ? true : false
                              }
                              onChange={() => this.handleChange(index)}
                            />
                          }
                          label={
                            <Typography
                              style={{
                                fontSize: "1rem",
                                fontWeight: "500",
                              }}
                            >
                              {planInfo?.description} $
                              {planInfo?.description_price}
                            </Typography>
                          }
                        />
                      </div>
                      <div
                        className={
                          this.state.checked === index
                            ? "membership__btn btn-default"
                            : "membership__btn"
                        }
                        onClick={() => this.handleLetsToThis(planInfo, index)}
                      >
                        <p>
                          let's do this!
                          <ArrowRightAltIcon />
                        </p>
                      </div>
                    </Card>
                  );
                })}
              </Fragment>
            </div>
          </Fragment>
        )}

        {this.state.tabNumber === 2 && (
          <Fragment>
            <div
              style={{
                display: "grid",
                justifyContent: "center",
              }}
            >
              {FitnessPoints.map((text, index) => (
                <div className="membership__point" key={index}>
                  <img src="/assets/images/check.svg" alt="check-icon"></img>
                  <p>{text}</p>
                </div>
              ))}
            </div>
            <div className="fitness__checkboxCon">
              <h3>Personal Training</h3>
              <input
                type="checkbox"
                name="btn"
                className="fitness__checkbox"
                onChange={this.handleFitnessCheckBox}
                defaultChecked={true}
              />
              <h3>Small group Training</h3>
            </div>
            <div className="membership__cardContainer">
              <Fragment>
                {this.state.Fitness.map((planInfo, index) => (
                  <Card className="membership__card" key={planInfo.id}>
                    <div style={{ textAlign: "center" }}>
                      <div>
                        <span>
                          <h1>${planInfo?.current_price}</h1>
                          {planInfo?.original_price && (
                            <span className="membership__linethrough">
                              ${planInfo?.original_price}
                            </span>
                          )}
                        </span>
                      </div>
                      <h2>
                        {planInfo?.months}
                        <span> months</span>
                      </h2>
                      {planInfo?.per_class && (
                        <p style={{ fontWeight: "500" }}>
                          ${planInfo?.per_class}
                          <span> per class</span>
                        </p>
                      )}
                      <h3
                        style={{
                          margin: 0,
                          padding: 0,
                          color: "hsl(0, 0%, 60%)",
                          lineHeight: 1.5,
                        }}
                      >
                        <span
                          style={{
                            display: planInfo?.discount ? "block" : "none",
                          }}
                        >
                          {planInfo?.discount}% off <br />
                        </span>
                        <span
                          style={{
                            display:
                              planInfo?.free_weeks || planInfo?.free_months
                                ? "block"
                                : "none",
                          }}
                        >
                          {planInfo?.free_weeks
                            ? `+${planInfo?.free_weeks}  Weeks Free`
                            : `+${planInfo?.free_months}  Month Free`}
                          <br />
                        </span>

                        <span
                          style={{
                            display: planInfo?.credits ? "block" : "none",
                          }}
                        >{`${planInfo?.credits} Credits `}</span>
                      </h3>
                    </div>
                    <div>
                      <FormControlLabel
                        style={{
                          margin: "1rem 2rem",
                          visibility: planInfo?.description
                            ? "visible"
                            : "hidden",
                        }}
                        control={
                          <GreenCheckbox
                            checked={
                              this.state.checked === index ? true : false
                            }
                            onChange={() => this.handleChange(index)}
                            name="checkedG"
                          />
                        }
                        label={
                          <Typography
                            style={{
                              fontSize: "1rem",
                              fontWeight: "500",
                            }}
                          >
                            {planInfo?.description} $
                            {planInfo?.description_price}
                          </Typography>
                        }
                      />
                    </div>
                    <div
                      className="membership__btn"
                      onClick={() => this.handleLetsToThis(planInfo, index)}
                    >
                      <p>
                        let's do this!
                        <ArrowRightAltIcon />
                      </p>
                    </div>
                  </Card>
                ))}
              </Fragment>
            </div>
          </Fragment>
        )}
        {this.state.tabNumber === 3 && (
          <Fragment>
            <div
              style={{
                display: "grid",
                justifyContent: "center",
              }}
            >
              {NutritionFitnessPoints.map((text, index) => (
                <div className="membership__point" key={index}>
                  <img src="/assets/images/check.svg" alt="check-icon"></img>
                  <p>{text}</p>
                </div>
              ))}
            </div>

            <div className="membership__cardContainer">
              <Fragment>
                {NutritionFitness.map((planInfo, index) => (
                  <Card className="membership__card" key={planInfo.id}>
                    <div style={{ textAlign: "center" }}>
                      <div>
                        <span>
                          <h1>${planInfo?.current_price}</h1>
                          {planInfo?.original_price && (
                            <span className="membership__linethrough">
                              ${planInfo?.original_price}
                            </span>
                          )}
                        </span>
                      </div>
                      <h2>
                        {planInfo?.months}
                        <span> months</span>
                      </h2>
                      {planInfo?.per_class && (
                        <p style={{ fontWeight: "500" }}>
                          ${planInfo?.per_class}
                          <span> per class</span>
                        </p>
                      )}
                      <h3
                        style={{
                          margin: 0,
                          padding: 0,
                          color: "hsl(0, 0%, 60%)",
                          lineHeight: 1.5,
                          display: planInfo?.discount ? "block" : "none",
                        }}
                      >
                        {planInfo?.discount}% off <br />
                        <span>
                          {planInfo?.free_weeks
                            ? `+${planInfo?.free_weeks}  Weeks Free`
                            : `+${planInfo?.free_months}  Month Free`}
                        </span>
                      </h3>
                    </div>
                    <div>
                      <FormControlLabel
                        style={{
                          margin: "1rem 2rem",
                          visibility: planInfo?.description
                            ? "visible"
                            : "hidden",
                        }}
                        control={
                          <GreenCheckbox
                            checked={
                              this.state.checked === index ? true : false
                            }
                            onChange={() => this.handleChange(index)}
                            name="checkedG"
                          />
                        }
                        label={
                          <Typography
                            style={{
                              fontSize: "1rem",
                              fontWeight: "500",
                            }}
                          >
                            {planInfo?.description} $
                            {planInfo?.description_price}
                          </Typography>
                        }
                      />
                    </div>
                    <div
                      className="membership__btn"
                      onClick={() => this.handleLetsToThis(planInfo, index)}
                    >
                      <p>
                        let's do this!
                        <ArrowRightAltIcon />
                      </p>
                    </div>
                  </Card>
                ))}
              </Fragment>
            </div>
          </Fragment>
        )}

        {this.state.tabNumber === 4 && (
          <Fragment>
            <div className="membership__cardContainer">
              <Fragment>
                {Yoga.map((planInfo, index) => (
                  <Card className="membership__card" key={planInfo.id}>
                    <div style={{ textAlign: "center" }}>
                      <div>
                        <span>
                          <h1>${planInfo?.current_price}</h1>
                          {planInfo?.original_price && (
                            <span className="membership__linethrough">
                              ${planInfo?.original_price}
                            </span>
                          )}
                        </span>
                      </div>
                      <h2>
                        {/* {planInfo?.months} */}
                        <span> per month</span>
                      </h2>
                      {planInfo?.per_class && (
                        <p style={{ fontWeight: "500" }}>
                          ${planInfo?.per_class}
                          <span> per class</span>
                        </p>
                      )}
                      <h3
                        style={{
                          margin: 0,
                          padding: 0,
                          color: "hsl(0, 0%, 60%)",
                          lineHeight: 1.5,
                          display: planInfo?.discount ? "block" : "none",
                        }}
                      >
                        {planInfo?.discount}% off <br />
                        <span>
                          {planInfo?.free_weeks
                            ? `+${planInfo?.free_weeks}  Weeks Free`
                            : `+${planInfo?.free_months}  Month Free`}
                        </span>
                      </h3>
                    </div>
                    <div>
                      <h3
                        style={{
                          margin: "10px",
                          padding: 0,
                          color: "hsl(0, 0%, 60%)",
                          lineHeight: 1.5,
                        }}
                      >
                        {planInfo?.description} <br />
                        {planInfo?.time}
                      </h3>
                    </div>
                    <div
                      className="membership__btn"
                      onClick={() => this.handleLetsToThis(planInfo, index)}
                    >
                      <p>
                        let's do this!
                        <ArrowRightAltIcon />
                      </p>
                    </div>
                  </Card>
                ))}
              </Fragment>
            </div>
          </Fragment>
        )}
      </Fragment>
    );
  }
}

const GreenCheckbox = withStyles({
  root: {
    "&$checked": {
      color: green[600],
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

export default withRouter(MembershipPage);
