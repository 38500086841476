import React from 'react';
import { Header } from 'semantic-ui-react';
import '../../components/static/App.css';
import './weightlosschallenge.css';
const WeightLossPage = ({history}) =>{
    return(
        <div className='Weight-Div'>
            <main className='Weight-Main'>
                <div className='Weight-Main-Div' >
                    <div>
                    <Header as='h2'>Join Today and Win a $500 Visa Gift Card</Header>
                    <button className='signup buttcss' onClick={() => {
                            history.push({
                                pathname: "/register",
                                state: {
                                    plan:'6weeks',
                                    ref: '6weeks'
                                }
                            })
                         }}>Join The Weight Loss Challenge</button>
                    </div>
                </div>
                <div>
                    <img src='./assets/images/deevefit_healthy_habits.png' alt=''/>
                </div>
            </main>
            <Describe/>
            <ImageDescribe history = {history}/>
        </div>
    )
}
export default WeightLossPage;

const Describe = () =>{
    return(
        <main className='Decsribe-Main'>
            <Header as='h3' style={{color:'dodgerBlue'}}>What do you get during these 6 weeks?</Header>
            <ul>
                <li><span>Up to 3 online personal training serious for 30 minutes each per week.</span></li>
                <li><span>Personalized consultation on nutrition training and supplementation.</span></li>
                <li><span>Weekly monitoring and course correction.</span></li>
                <li><span>Access to meal plan recommendations.</span></li>
                <li><span>Access to deevefit exclusive content.</span></li>
                <li><span>Measurements tracking.</span></li>
                <li><span>Workout tracker.</span></li>
            </ul>
        </main>
    )
}

const ImageDescribe = ({history}) =>{
    return(
        <div className='ImageDescribe-Div'>
        <Header as='h4' style={{ color: 'dodgerBlue'}}>HOW IT WORKS</Header>
        <main className='ImageDescribe-Main'>
            <div className='ImageDescribe-Main-Div'>
                <div>
                    <img src='./assets/images/deevefit_fitness_tracking.png' alt=''/>
                </div>
                    <button className='signup buttcss2' onClick={() => {
                        history.push({
                            pathname: "/register",
                            state: {
                                plan: '6weeks',
                                ref: '6weeks'
                            }
                        })
                    }}>JOIN THE CHALLENGE</button>
            </div>
            <div className='ImageDescribe-Main-Div'>
                <div>
                    <img src='./assets/images/deevefit_weight_measure_image.png' alt='' />
                </div>
                    <button className='signup buttcss2' onClick={() => {
                        history.push({
                            pathname: "/register",
                            state: {
                                plan: '6weeks',
                                ref: '6weeks'
                            }
                        })
                    }}>LOSE WEIGHT</button>
            </div>
            <div className='ImageDescribe-Main-Div'>
                <div>
                    <img src='./assets/images/gift-card.jpg' alt='' />
                </div>
                    <button className='signup buttcss2' onClick={() => {
                        history.push({
                            pathname: "/register",
                            state: {
                                plan: '6weeks',
                                ref: '6weeks'
                            }
                        })
                    }}>WIN THE PRIZE</button>
            </div>
        </main>
        </div>
    )
}

// const Logo = () =>{
//     return(
//         <main className='Logo-Main'>
//             <div>
//             <img src='./assets/images/favicon.png' alt=''/>
//             </div>
//             <Header as='h4' style={{ color:'#4040dd',margin:'0'}}>Deeve</Header>
//             <span style={{color:'dodgerblue'}}>Fit</span>
//         </main>
//     )
// }

// const Contact = () =>{
//     return(
//         <main className='Contact-Main'>
//             <div className='Contact-Main-Div'>
//                 <div>
//                     <img src='./assets/images/deevefit_phone.png' alt=''/>
//                 </div>
//                 <Header as='h4'>CALL US</Header>
//                 <span>623-282-2793</span>
//             </div>
//             <div className='Contact-Main-Div'>
//                 <div>
//                     <img src='./assets/images/deevefit_email.png' alt=''/>
//                 </div>
//                 <Header as='h4'>MAIL US</Header>
//                 <span>support@deevefit.com</span>
//             </div>
//         </main>
//     )
// }