import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";
import "firebase/functions";
import "firebase/messaging";
import "firebase/storage";
import { isDesktop } from "react-device-detect";
import { firebaseConfig, firebaseSecrets } from "./secrets";

// const firebaseApp = firebase.initializeApp(firebaseConfig, "deevefit");

export const firebaseApp = firebase.initializeApp(firebaseConfig);

export const adminFireAuth = firebaseApp.auth();
export const fireDatabase = firebaseApp.database();
export const fireAuth = firebaseApp.auth();
export const fireFunctions = firebaseApp.functions();
export const fireStorage = firebaseApp.storage();

let messagingHandler = null;
// if (isDesktop) {
//   messagingHandler = firebaseApp.messaging();
//   // FIXME
//   messagingHandler.getToken({ vapidKey: firebaseConfig.messagingSenderId });
// }

export const messaging = messagingHandler;

export default firebaseApp;
