import React, { Component } from "react";
import { withRouter } from "react-router";
class Nav extends Component {
  state = {
    open: false,
  };

  render() {
    var state = this.props.status;
    var home = state.home ? "nav-item activ" : "nav-item";
    var services = state.services ? "nav-item activ" : "nav-item";
    var how = state.how ? "nav-item activ" : "nav-item";
    // var what = state.what ? "nav-item activ" : "nav-item";
    // var features = state.features ? "nav-item activ" : "nav-item";
    // var programs = state.programs ? "nav-item activ" : "nav-item";
    var price = state.price || state.getstarted ? "nav-item activ" : "nav-item";
    var faq = state.faq ? "nav-item activ" : "nav-item";
    // const { open } = this.state;
    return (
      <div>
        <nav
          className="navbar navbar-expand-lg navbar-light bg-white"
          style={{ padding: "10px 0", margin: "0", zIndex: "1" }}
        >
          <div className="container w-100 navi">
            <a className="navbar-brand" href="/">
              <img
                src="assets/images/logo2.svg"
                className="img-responsive"
                style={{ paddingLeft: "0em", paddingTop: "0.3em" }}
                alt="Deevefit"
                height="34px"
              />
            </a>
            <button
              className="navbar-toggler react-"
              type="button"
              data-toggle="collapse"
              style={{
                marginTop: "0.3em",
                marginRight: "2em",
                marginBottom: "0.3em",
              }}
              data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>

            <div
              className="collapse navbar-collapse my-2"
              id="navbarSupportedContent"
            >
              <ul
                className="navbar-nav ml-auto mt-2 mt-lg-0"
                style={{ fontSize: "14px" }}
              >
                {/* <li className={home} style={{paddingTop:"0.25em"}}>
              <a className="nav-link" href="/"  >Home <span className="sr-only">(current)</span></a>
            </li> */}
                <li className={home} style={{ paddingTop: "0.25em" }}>
                  <a
                    className="nav-link"
                    href="/"
                    onClick={() => {
                      var status = {
                        home: true,
                        services: false,
                        how: false,
                        programs: false,
                        features: false,
                        price: false,
                        faq: false,
                        getstarted: false,
                        testimonials: false,
                      };
                      this.props.onSelectNav({
                        hero: true,
                        services: true,
                        faq: false,
                        goal: true,
                        programs: true,
                        features: true,
                        how: true,
                        benefits: true,
                        price: false,
                        terms: false,
                        privacy: false,
                        testimonials: true,
                        status: status,
                      });
                    }}
                  >
                    Home
                  </a>
                </li>
                <li className={services} style={{ paddingTop: "0.25em" }}>
                  <a
                    className="nav-link"
                    href="#services"
                    onClick={() => {
                      var status = {
                        home: false,
                        services: true,
                        how: false,
                        programs: false,
                        features: false,
                        price: false,
                        faq: false,
                        getstarted: false,
                        testimonials: false,
                      };
                      this.props.onSelectNav({
                        hero: true,
                        services: true,
                        faq: false,
                        goal: true,
                        programs: true,
                        features: true,
                        how: true,
                        benefits: true,
                        price: false,
                        terms: false,
                        privacy: false,
                        testimonials: true,
                        status: status,
                      });
                    }}
                  >
                    Services
                  </a>
                </li>
                <li className={how} style={{ paddingTop: "0.25em" }}>
                  <a
                    className="nav-link"
                    href="#howitworks"
                    onClick={() => {
                      var status = {
                        home: false,
                        services: false,
                        how: true,
                        programs: false,
                        features: false,
                        price: false,
                        faq: false,
                        getstarted: false,
                        testimonials: false,
                      };
                      this.props.onSelectNav({
                        hero: true,
                        services: true,
                        faq: false,
                        goal: true,
                        programs: true,
                        features: true,
                        how: true,
                        benefits: true,
                        price: false,
                        terms: false,
                        privacy: false,
                        testimonials: true,
                        status: status,
                      });
                    }}
                  >
                    How it works
                  </a>
                </li>
                {/* <li className={features} style={{paddingTop:"0.25em"}}>
              <a className="nav-link" href="#features" onClick={()=>{var status = {home:false,services:false,how:false,programs:false,features:true,price:false,faq:false,getstarted:false,testimonials:false}; this.props.onSelectNav({hero:true,services:true,faq:false,goal:true,programs:true,features:true,how:true,benefits:true,price:false,terms:false,privacy:false,testimonials:true,status:status});}}>Features</a>
            </li> */}
                {/* <li className={programs} style={{ paddingTop: "0.25em" }}>
                  <a
                    className="nav-link"
                    href="#programs"
                    onClick={() => {
                      var status = {
                        home: false,
                        services: false,
                        how: false,
                        programs: true,
                        features: false,
                        price: false,
                        faq: false,
                        getstarted: false,
                        testimonials: false,
                      };
                      this.props.onSelectNav({
                        hero: true,
                        services: true,
                        faq: false,
                        goal: true,
                        programs: true,
                        features: true,
                        how: true,
                        benefits: true,
                        price: false,
                        terms: false,
                        privacy: false,
                        testimonials: true,
                        status: status,
                      });
                    }}
                  >
                    Membership Plans
                  </a>
                </li> */}
                <li className={price} style={{ paddingTop: "0.25em" }}>
                  <a
                    className="nav-link"
                    href="/getstarted"
                    onClick={() => {
                      var status = {
                        home: false,
                        services: false,
                        how: false,
                        programs: false,
                        features: false,
                        price: true,
                        faq: false,
                        getstarted: false,
                        testimonials: false,
                      };
                      this.props.onSelectNav({
                        hero: false,
                        services: false,
                        what: false,
                        faq: false,
                        goal: false,
                        programs: false,
                        features: false,
                        how: false,
                        benefits: false,
                        price: true,
                        terms: false,
                        privacy: false,
                        testimonials: false,
                        status: status,
                      });
                    }}
                  >
                    Membership
                  </a>
                </li>{" "}
                {/* #Membership replaced with getstarted in above hyperlink */}
                <li className={faq} style={{ paddingTop: "0.25em" }}>
                  <a
                    className="nav-link"
                    href="#faq"
                    onClick={() => {
                      var status = {
                        home: false,
                        services: false,
                        how: false,
                        programs: false,
                        features: false,
                        price: false,
                        faq: true,
                        getstarted: false,
                        testimonials: false,
                      };
                      this.props.onSelectNav({
                        hero: false,
                        services: false,
                        faq: true,
                        goal: false,
                        programs: false,
                        features: false,
                        how: false,
                        benefits: false,
                        price: false,
                        terms: false,
                        privacy: false,
                        testimonials: false,
                        status: status,
                      });
                    }}
                  >
                    FAQ
                  </a>
                </li>
                <li className="nav-item" style={{ paddingTop: "0.25em" }}>
                  <a className="nav-link" href="https://blog.deevefit.com">
                    Blog
                  </a>
                </li>
                {/* <li className="nav-button">
              <a className="nav-link" href="login"><button>Login</button></a>
            </li>
            <li className="nav-button">
            <Modal
                size="small"
                className="modal-css"
                open={open}
                trigger={ <button style={{position:'relative',top:'6px'}}onClick={() =>this.setState({open:true})}>Start your FREE trial</button>}>
                <Icon name='close' onClick={() => this.setState({open:false})}/>
                <Modal.Content scrolling>
                <FreeModalSignUp/>
                </Modal.Content>
            </Modal>
            </li> */}
              </ul>
            </div>
          </div>
        </nav>
      </div>
    );
  }
  // Start your free trial link replaced from #home to "/getstarted"
}

export default withRouter(Nav);
