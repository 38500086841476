import React from "react";
import {
  Message,
  Button,
  Form,
  Icon,
  Grid,
  Loader,
  Image,
} from "semantic-ui-react";
import { fireAuth } from "../../utils/fire";

export default class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      message: "",
      forget: false,
      register: false,
    };
  }

  validateAuthentication = (event) => {
    event.preventDefault();
    const email = this.state.email.trim();
    const password = this.state.password.trim();
    if (this.validateInput(email, password)) {
      this.fireBaseAuthentication(email, password);
    }
  };

  resetPassword = async (event) => {
    try {
      const emailInDbMatch = await fireAuth.fetchSignInMethodsForEmail(
        this.state.email
      );
      const didEmailInDbMatch =
        (await emailInDbMatch.length) === 0 ? false : true;
      if (!didEmailInDbMatch) {
        await this.setState({
          message: "No user found by that email",
        });
      } else {
        const email = this.state.email.trim();
        fireAuth.sendPasswordResetEmail(email);
        this.setState({
          forget: false,
          message: "Enter registered email and password to get started",
        });
      }
    } catch (err) {
      await this.setState({
        message: err.message,
      });
    }
  };

  validateInput(email, password) {
    if (email === "" || password === "") {
      this.setState({
        message: "Both email & password have to be entered",
      });
      return false;
    } else {
      // eslint-disable-next-line
      const regex =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (!regex.test(email)) {
        this.setState({
          message: "Please make sure it is a valid email",
        });
        return false;
      } else if (password !== "") {
        const minLen = password.length;
        if (minLen < 6) {
          this.setState({
            message: "Password needs atleast 6 characters",
          });
          return false;
        } else {
          this.setState({
            message: "Checking for authentication",
          });
          return true;
        }
      }
    }
  }

  async fireBaseAuthentication(email, password) {
    try {
      const emailInDbMatch = await fireAuth.fetchSignInMethodsForEmail(email);

      const didEmailInDbMatch =
        (await emailInDbMatch.length) === 0 ? false : true;
      if (!didEmailInDbMatch) {
        await this.setState({
          message: "No user found by that email",
        });
      } else {
        const res = await fireAuth.signInWithEmailAndPassword(email, password);

        localStorage.setItem("isAuthenticated", true);
        this.props.history.replace("/dashboard");
      }
    } catch (err) {
      await this.setState({
        message: err.message,
      });
    }
  }

  render() {
    return (
      <div
        style={{
          background: "linear-gradient(to right, #0088EE 0%, #00B1FF 100%)",
          height: "-webkit-fill-available",
          width: "-webkit-fill-available",
          position: "fixed",
        }}
      >
        <div
          className="login-form"
          style={{
            height: "90vh",
            width: "90vw",
            backgroundColor: "white",
            marginTop: "5vh",
            marginLeft: "5vw",
            overflowY: "auto",
            overflowX: "hidden",
          }}
        >
          <Grid
            style={{ fontFamily: "'Montserrat', sans-serif", height: "100%" }}
          >
            <Grid.Column
              computer={1}
              tablet={1}
              only="computer tablet"
            ></Grid.Column>
            <Grid.Column
              textAlign="center"
              verticalAlign="bottom"
              computer={5}
              tablet={6}
              only="computer tablet"
              style={{ paddingBottom: "7em" }}
            >
              <a href="/" style={{ position: "fixed", top: "40vh" }}>
                <Image
                  floated="right"
                  src="assets/images/logo2.svg"
                  size="small"
                  alt="logo"
                />
              </a>
              Don't have an account?{" "}
              <a className="cool-link" href="/getstarted">
                Register
              </a>
            </Grid.Column>
            <Grid.Column computer={3} tablet={1} mobile={2}>
              <div
                className="d-none d-lg-block d-md-block"
                style={{
                  width: "1px",
                  height: "80%",
                  marginTop: "5em",
                  marginLeft: "40%",
                  backgroundColor: "grey",
                }}
              ></div>
            </Grid.Column>
            <Grid.Column
              computer={5}
              tablet={7}
              mobile={10}
              verticalAlign="middle"
            >
              <div
                className="w-100 d-sm-block d-md-none d-lg-none"
                style={{ height: "5em" }}
              >
                <a href="/">
                  <Image
                    floated="left"
                    src="assets/images/logo2.svg"
                    size="small"
                    alt="logo"
                  />
                </a>
              </div>
              {this.state.forget && (
                <a
                  href="#home"
                  onClick={() =>
                    this.setState({
                      message: "",
                      forget: false,
                      register: false,
                    })
                  }
                >
                  <Icon
                    name="arrow circle left"
                    size="large"
                    style={{
                      position: "relative",
                      left: "-5%",
                      marginBottom: "3em",
                    }}
                  ></Icon>
                </a>
              )}
              <Form size="large">
                <Form.Input
                  fluid
                  label="Email"
                  icon="user"
                  iconPosition="left"
                  placeholder="Email"
                  type="email"
                  value={this.state.email}
                  onChange={(event) =>
                    this.setState({ email: event.target.value })
                  }
                  id="useremail"
                />
                {!this.state.forget && (
                  <Form.Input
                    fluid
                    icon="lock"
                    id="userpassword"
                    label="Password"
                    iconPosition="left"
                    labelPosition="left"
                    placeholder="Password"
                    type="password"
                    value={this.state.password}
                    onChange={(event) =>
                      this.setState({ password: event.target.value })
                    }
                  />
                )}

                {!this.state.forget && (
                  <span style={{ display: "inline-block" }}>
                    <Button
                      size="large"
                      id="login-button"
                      compact
                      onClick={this.validateAuthentication}
                    >
                      Login
                    </Button>
                    <div className="w-100 d-block d-md-none d-lg-none mt-3"></div>
                    {!(this.state.forget || this.state.register) && (
                      <a
                        className="cool-link"
                        href="#home"
                        onClick={() => {
                          this.setState({
                            forget: true,
                            register: false,
                            message:
                              "Enter email id for sending password reset link",
                          });
                        }}
                      >
                        Forgot Password?
                      </a>
                    )}
                  </span>
                )}

                {this.state.forget && (
                  <Button large id="login-button" onClick={this.resetPassword}>
                    Reset Password
                  </Button>
                )}
              </Form>
              {this.state.message && (
                <Message
                  style={{
                    paddingLeft: "1em",
                    paddingRight: "1em",
                    textAlign: "center",
                  }}
                >
                  {this.state.message === "Checking for authentication" ? (
                    <Loader active inline="centered" />
                  ) : (
                    this.state.message
                  )}
                </Message>
              )}
            </Grid.Column>
          </Grid>
        </div>
      </div>
    );
  }
}
