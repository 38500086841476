import moment from "moment-timezone";
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import {
  Button,
  Card,
  Container,
  Grid,
  Icon,
  List,
  Tab,
} from "semantic-ui-react";
import { fireAuth, fireDatabase } from "../../utils/fire";
import MembershipPage from "../MembershipPage/MembershipPage";
// const oneTimeINR = 499;
// const oneTime = 4.99;
// const gold_inr = 9999;
// const gold_2020 = 199;
// const bronze_inr = 3999;
// const bronze_2020 = 69;
// const silver_inr = 5999;
// const silver_2020 = 99;
// const week12PriceINR = 11990;
// const week12Price = 433;



class Membership_Step2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      toEmail: "",
      p: false,
      r: false,
      show: false,
      checkout: false,
      pay: false,
      message: "",
      name: "",
    };
    this.registerUser = this.registerUser.bind(this);
    this.togglep = this.togglep.bind(this);
    this.toggler = this.toggler.bind(this);
    this.check = this.check.bind(this);
  }

  togglep() {
    this.setState({ p: !this.state.p });
    if (this.state.p === false) this.setState({ r: false });
  }
  toggler() {
    this.setState({ r: !this.state.r });
    if (this.state.r === false) this.setState({ p: false });
  }

  validateInput(email, password) {
    if (email === "" || password === "") {
      this.setState({
        message: "Both email & password have to be entered",
      });
      return false;
    } else if (this.state.plan === "") {
      this.setState({
        message: "You must select a plan",
      });
      return false;
    } else {
      // eslint-disable-next-line
      const regex =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (!regex.test(email)) {
        this.setState({
          message: "Please make sure it is a valid email",
        });
        return false;
      } else if (password !== "") {
        const minLen = password.length;
        if (minLen < 6) {
          this.setState({
            message: "Password needs atleast 6 characters",
          });
          return false;
        } else {
          return true;
        }
      }
    }
  }

  validateUser = async (e) => {
    e.preventDefault();
    //console.log(this.state);
    if (this.validateInput(this.state.email, this.state.password)) {
      try {
        const emailMatch = await fireAuth.fetchSignInMethodsForEmail(
          this.state.email
        );
        const doesExist = (await emailMatch.length) === 0 ? false : true;
        if (doesExist) {
          this.setState({
            message: "Email ID is already in use by another user.",
          });
        } else {
          this.setState({
            ready: true,
            checkout: true,
            pay: true,
            onetime: false,
          });
        }
      } catch (err) {
        this.setState({ message: err.message });
      }
    }
  };

  check = (event) => {
    if (this.validateInput(this.state.email, this.state.password)) {
      this.setState({ pay: true, message: "", two: true, one: false });
    }
  };

  registerUser = async (email, password) => {
    if (this.validateInput(email, password)) {
      try {
        await fireAuth.createUserWithEmailAndPassword(
          this.state.email,
          this.state.password
        );
        var ref = fireDatabase.ref("USERS/" + fireAuth.currentUser.uid);
        await ref.set({
          uid: fireAuth.currentUser.uid,
          email: this.state.email,
          category: "student",
          displayName: this.state.name,
          credits: 1,
          plan: "One Time",
        });
        //console.log('state', this.state);
        await fireDatabase
          .ref("One Time/" + fireAuth.currentUser.uid)
          .set({ email: this.state.email });
        var countRef = await fireDatabase.ref("Stats/One Time").once("value");
        var count = countRef.val() + 1;
        await fireDatabase.ref("Stats/One Time").set(count);
      } catch (error) {
        await this.setState({ message: error.message });
      }
    }
  };
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  getTimeZone = () => {
    const tzone = moment.tz.guess();
    if (tzone.includes("America")) {
      return "America";
    } else if (tzone.includes("Asia")) {
      return "Asia";
    }
  };

  // render() {
  //   const { q6, q7 } = this.props;
  //   let tzone = this.getTimeZone();
  //   console.log(tzone);
  //   if (!this.state.checkout && tzone === "Asia")
  //     return (
  //       <div style={{ marginTop: "7vh", height: "min-content" }}>
  //         <div className="container-fluid" id="Membership">
  //           {/* { q7 !== "None" && } */}
  //           {/* <Weeks12 plan={"12weeks_inr"} currency={"\u20B9"} price={"11,990"}/> */}
  //           {q7 === "None" && q6 === "No" && (
  //             <Individual
  //               plan={"12weeks_inr"}
  //               currency={"\u20B9"}
  //               price={"11,990"}
  //             />
  //           )}
  //           {/* 12 week added*/}
  //           {/* Old plans */}
  //           <br />

  //           {/* <!-- ==================
  // Membership --> */}
  //           <PlanOption tzone={tzone} />
  //         </div>
  //         {/* <!-- =================
  // ONE DAY Membership --> */}

  //         <div
  //           className="container-fluid"
  //           style={{ height: "min-content", marginBottom: "2em" }}
  //         >
  //           <div className="row trial">
  //             <div className="w-100 col-1 d-sm-none d-lg-block d-md-block" />
  //             <div className="col-md-5  margins">
  //               <span className="heading">
  //                 {"Sign up for one class with no commitment for " +
  //                   "\u20B9" +
  //                   oneTimeINR}
  //               </span>
  //               <br />
  //               <br />
  //             </div>
  //             <div className="col-md-4 startcard">
  //               <div className="container-fluid">
  //                 <div className="row">
  //                   <div className="col-md-12  benifitscard">
  //                     <br />
  //                     <br />
  //                     <center>
  //                       <span className="headingprice">
  //                         {"\u20B9" + oneTimeINR}
  //                       </span>
  //                       <br />
  //                       <br />

  //                       <button
  //                         className="signup"
  //                         onClick={() => this.setState({ onetime: true })}
  //                       >
  //                         Let's Start
  //                       </button>
  //                       <br />
  //                       <br />
  //                     </center>
  //                   </div>
  //                 </div>
  //               </div>
  //             </div>
  //           </div>
  //         </div>
  //         {/* For the one time payment dialog box */}
  //         {this.state.onetime && (
  //           <div className="ui raised centered cards">
  //             <div
  //               className="ui card"
  //               style={{ margin: "4em", width: "min-content" }}
  //             >
  //               <Header
  //                 content="Register for One class"
  //                 style={{ margin: "1em" }}
  //               />
  //               <Card.Content>
  //                 <Form size="huge">
  //                   <Form.Group>
  //                     <Form.Input
  //                       required
  //                       type="text"
  //                       placeholder="Enter your name"
  //                       value={this.state.name}
  //                       onChange={(event) =>
  //                         this.setState({ name: event.target.value })
  //                       }
  //                     />
  //                     <Form.Input
  //                       required
  //                       type="email"
  //                       placeholder="Enter e-mail address"
  //                       value={this.state.email}
  //                       onChange={(event) =>
  //                         this.setState({ email: event.target.value })
  //                       }
  //                     />
  //                     <Form.Input
  //                       required
  //                       type="password"
  //                       placeholder="Create a password"
  //                       value={this.state.password}
  //                       onChange={(event) =>
  //                         this.setState({ password: event.target.value })
  //                       }
  //                     />
  //                   </Form.Group>
  //                 </Form>
  //                 {this.state.message && (
  //                   <Message>{this.state.message}</Message>
  //                 )}
  //               </Card.Content>
  //               <Card.Content extra>
  //                 <button onClick={this.validateUser}>Sign up</button>
  //               </Card.Content>
  //             </div>
  //           </div>
  //         )}
  //       </div>
  //     );
  //   else if (!this.state.checkout)
  //     return (
  //       <div style={{ marginTop: "7vh", height: "min-content" }}>
  //         <div className="container-fluid" id="Membership">
  //           {/* {q7 !== "None" && <Weeks12 plan={"12weeks"} currency={"$"} price={"433"}/>}
  //               {q7 === "None" && q6==="No" && <Individual plan = {"12weeks"} currency={"$"} price={"433"}/>} */}
  //           {/* 12 week added*/}
  //           {/* Old plans */}
  //           <br />

  //           {/* <!-- ==================
  // Membership --> */}
  //           <PlanOption />
  //         </div>

  //         {/* <!-- =================
  //     ONE DAY Membership --> */}

  //         <div
  //           className="container-fluid"
  //           style={{ height: "min-content", marginBottom: "2em" }}
  //         >
  //           <div className="row trial">
  //             <div className="w-100 col-1 d-sm-none d-lg-block d-md-block" />
  //             <div className="col-md-5  margins">
  //               <span className="heading">
  //                 Sign up for one class with no commitment for ${oneTime}
  //               </span>
  //               <br />
  //               <br />
  //             </div>
  //             <div className="col-md-4 startcard">
  //               <div className="container-fluid">
  //                 <div className="row">
  //                   <div className="col-md-12  benifitscard">
  //                     <br />
  //                     <br />
  //                     <center>
  //                       <span className="headingprice">${oneTime}</span>
  //                       <br />
  //                       <br />

  //                       <button
  //                         className="signup"
  //                         onClick={() => this.setState({ onetime: true })}
  //                       >
  //                         Let's Start
  //                       </button>
  //                       <br />
  //                       <br />
  //                     </center>
  //                   </div>
  //                 </div>
  //               </div>
  //             </div>
  //           </div>
  //         </div>

  //         {this.state.onetime && (
  //           <div className="ui raised centered cards">
  //             <div
  //               className="ui card"
  //               style={{ margin: "4em", width: "min-content" }}
  //             >
  //               <Header
  //                 content="Register for One class"
  //                 style={{ margin: "1em" }}
  //               />
  //               <Card.Content>
  //                 <Form size="huge">
  //                   <Form.Group>
  //                     <Form.Input
  //                       required
  //                       type="text"
  //                       placeholder="Enter your name"
  //                       value={this.state.name}
  //                       onChange={(event) =>
  //                         this.setState({ name: event.target.value })
  //                       }
  //                     />
  //                     <Form.Input
  //                       required
  //                       type="email"
  //                       placeholder="Enter e-mail address"
  //                       value={this.state.email}
  //                       onChange={(event) =>
  //                         this.setState({ email: event.target.value })
  //                       }
  //                     />
  //                     <Form.Input
  //                       required
  //                       type="password"
  //                       placeholder="Create a password"
  //                       value={this.state.password}
  //                       onChange={(event) =>
  //                         this.setState({ password: event.target.value })
  //                       }
  //                     />
  //                   </Form.Group>
  //                 </Form>
  //                 {this.state.message && (
  //                   <Message>{this.state.message}</Message>
  //                 )}
  //               </Card.Content>
  //               <Card.Content extra>
  //                 <button onClick={this.validateUser}>Sign up</button>
  //               </Card.Content>
  //             </div>
  //           </div>
  //         )}
  //       </div>
  //     );
  //   else
  //     return (
  //       <div>
  //         <Grid
  //           style={{
  //             height: "100%",
  //             marginLeft: "20%",
  //             marginTop: "5%",
  //             marginBottom: "4em",
  //           }}
  //         >
  //           <Grid.Column
  //             width={6}
  //             style={{ height: "100%", marginLeft: "20%", marginTop: "5%" }}
  //           >
  //             <Header
  //               as="h1"
  //               style={{
  //                 marginLeft: "20%",
  //                 fontFamily: "verdana",
  //                 fontSize: "24px",
  //               }}
  //             >
  //               One Time Payment
  //             </Header>
  //             {this.state.pay && tzone === "Asia" && (
  //               <StripeProvider apiKey={pubKey}>
  //                 <Checkout
  //                   name={this.state.name}
  //                   email={this.state.email}
  //                   password={this.state.password}
  //                   registerUser1={(e, p) => this.registerUser(e, p)}
  //                   getmsg={(msg) => this.setState({ message: msg })}
  //                   plan="One time INR"
  //                   ready={this.state.ready}
  //                 />
  //               </StripeProvider>
  //             )}
  //             {this.state.pay && tzone === "America" && (
  //               <StripeProvider apiKey={pubKey}>
  //                 <Checkout
  //                   name={this.state.name}
  //                   email={this.state.email}
  //                   password={this.state.password}
  //                   registerUser1={(e, p) => this.registerUser(e, p)}
  //                   getmsg={(msg) => this.setState({ message: msg })}
  //                   plan="One time"
  //                   ready={this.state.ready}
  //                 />
  //               </StripeProvider>
  //             )}
  //             {this.state.message && (
  //               <Message>
  //                 {this.state.message === "Checking for authentication" ? (
  //                   <Loader active inline="centered" />
  //                 ) : (
  //                   this.state.message
  //                 )}
  //               </Message>
  //             )}
  //             <div style={{ fontSize: "11px" }}>
  //               By clicking CONTINUE, you are confirming that you are atleast 18
  //               years of age and agree to our{" "}
  //               <a href="/privacy">Privacy policy</a> and{" "}
  //               <a href="/terms">Terms of Use</a>
  //             </div>
  //           </Grid.Column>
  //         </Grid>
  //       </div>
  //     );
  // }

  panes = [
    {
      menuItem: "Nutrition",
      render: () => {
        return (
          <Tab.Pane attached={false}>
            <Container className="plan_container">
              <Grid stackable columns={4} centered>
                <Grid.Row>
                  <Grid.Column>
                    <Card>
                      <Card.Content>
                        <Card.Header>3 months pack</Card.Header>
                        <Card.Description>
                          <List>
                            <List.Item>
                              <Icon name="check circle" />2 or 3 sessions per
                              week as per your choice
                            </List.Item>
                            <List.Item>
                              <Icon name="check circle" />
                              {/* <List.Description>list 1</List.Description> */}
                              Access to Deevefit exclusive content.
                            </List.Item>
                            <List.Item>
                              <Icon name="check circle" />3 Months
                            </List.Item>
                          </List>
                        </Card.Description>
                      </Card.Content>
                      <Card.Content extra>
                        75$
                        <Button basic color="blue">
                          Choose
                        </Button>
                      </Card.Content>
                    </Card>
                  </Grid.Column>
                  <Grid.Column>
                    <Card>
                      <Card.Content>
                        <Card.Header>6 months pack</Card.Header>
                        {/* <Card.Meta>
                      <span className="date">Joined in 2015</span>
                    </Card.Meta> */}
                        <Card.Description>
                          <List>
                            <List.Item>
                              <Icon name="check circle" />2 or 3 sessions per
                              week as per your choice
                            </List.Item>
                            <List.Item>
                              <Icon name="check circle" />
                              {/* <List.Description>list 1</List.Description> */}
                              Access to Deevefit exclusive content.
                            </List.Item>
                            <List.Item>
                              <Icon name="check circle" />6 months
                            </List.Item>
                          </List>
                        </Card.Description>
                      </Card.Content>
                      <Card.Content extra>
                        75$
                        <Button basic color="blue">
                          Choose
                        </Button>
                      </Card.Content>
                    </Card>
                  </Grid.Column>
                  <Grid.Column>
                    <Card>
                      <Card.Content>
                        <Card.Header>
                          Personal Fitness + Nutrition Pack
                        </Card.Header>
                        <Card.Description>
                          <List>
                            <List.Item>
                              <Icon name="check circle" />2 or 3 sessions per
                              week as per your choice
                            </List.Item>
                            <List.Item>
                              <Icon name="check circle" />
                              {/* <List.Description>list 1</List.Description> */}
                              Personal Trainer
                            </List.Item>
                            <List.Item>
                              <Icon name="check circle" />
                              Access to Deevefit exclusive content.
                            </List.Item>
                          </List>
                        </Card.Description>
                      </Card.Content>
                      <Card.Content extra>
                        75$
                        <Button basic color="blue">
                          Choose
                        </Button>
                      </Card.Content>
                    </Card>
                  </Grid.Column>
                  <Grid.Column>
                    <Card>
                      <Card.Content>
                        <Card.Header>
                          Small Group Fitness + Nutrition Pack
                        </Card.Header>
                        {/* <Card.Meta>
                      <span className="date">Joined in 2015</span>
                    </Card.Meta> */}
                        <Card.Description>
                          <List>
                            <List.Item>
                              <Icon name="check circle" />2 or 3 sessions per
                              week as per your choice
                            </List.Item>
                            <List.Item>
                              <Icon name="check circle" />
                              {/* <List.Description>list 1</List.Description> */}
                              Small Group Training
                            </List.Item>
                            <List.Item>
                              <Icon name="check circle" />
                              Access to Deevefit exclusive content.
                            </List.Item>
                          </List>
                        </Card.Description>
                      </Card.Content>
                      <Card.Content extra>
                        75$
                        <Button basic color="blue">
                          Choose
                        </Button>
                      </Card.Content>
                    </Card>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Container>
          </Tab.Pane>
        );
      },
    },
    {
      menuItem: "One on One Personal Training",
      render: () => <Tab.Pane attached={false}>Tab 2 Content</Tab.Pane>,
    },
    {
      menuItem: "Small Group Personal Training",
      render: () => <Tab.Pane attached={false}>Tab 3 Content</Tab.Pane>,
    },
  ];

  render() {
    return <MembershipPage />;
  }
}

export default withRouter(Membership_Step2);
