import React, { Component } from "react";
import "./App.css";

class Features extends Component {
  render() {
    return (
      <div
        className="container-fluid"
        id="features"
        style={{
          width: "min(90vw, 70rem)",
          margin: "0 auto",
          background: "none",
        }}
      >
        <h2
          className="hd"
          style={{
            fontSize: "3.5rem",
            textAlign: "center",
            marginTop: "1.25em",
            marginBottom: "0",
          }}
        >
          1-ON-1 NUTRITION PROGRAM
        </h2>
        <h2
          className="hd"
          style={{
            textAlign: "center",
          }}
        >
          Lose weight | Get fit
        </h2>

        <div className="process-con">
          <div>
            <img
              src="assets/images/Get a coach.png"
              alt="get_deevefit_coach"
              style={{ height: "100%", width: "100%" }}
            />
          </div>
          <div>
            <div>
              <h2 className="hd card-number ">01</h2>
              <h2 className="hd process-card-title">MEET YOUR COACH</h2>
              <p>
                Start off with a quick consultation call about what your goals
                are, any injuries or health concerns that you may have, and any
                other important details to ensure you succeed.
              </p>
            </div>
          </div>
        </div>
        <div className="process-con">
          <div>
            <div className="text-r">
              <h2 className="hd card-number ">02</h2>
              <h2 className="hd process-card-title">MAKE A PLAN</h2>
              <p>
                Your coach will create a complete plan based on your goals and
                schedule. Each week you’ll have a new plan exclusive to you.
                Your plan is built based on your goals.
              </p>
            </div>
          </div>
          <div>
            <img
              src="assets/images/Make a plan.png"
              alt="get_custom_fitness_plan"
              style={{ height: "100%", width: "100%" }}
            />
          </div>
        </div>
        <div className="process-con">
          <div>
            <img
              src="assets/images/Break a sweat.png"
              alt="deevefit_fitness_plan"
              style={{ height: "100%", width: "100%" }}
            />
          </div>
          <div>
            <div>
              <h2 className="hd card-number ">03</h2>
              <h2 className="hd process-card-title">BREAK THE SWEAT</h2>
              <p>
                Work out as much as you want, when you want. It’ll be hard, But
                it’ll be totally worth it.
              </p>
            </div>
          </div>
        </div>
        <div className="process-con">
          <div>
            <div className="text-r">
              <h2 className="hd card-number ">04</h2>
              <h2 className="hd process-card-title">TRACK AND REFINE</h2>
              <p>
                As you progress through your journey, your coach will be there
                to keep you on track. You can message them whenever you would
                like for any quick answers.
              </p>
            </div>
          </div>
          <div>
            <img
              src="assets/images/Track and Refine.png"
              alt="make_your_own_fitness_plan"
              style={{ height: "100%", width: "100%" }}
            />
          </div>
        </div>
        {/* <div
                        className="container-fluid"
                        style={{
                        padding: "2em",
                        paddingBottom: "5em"
                    }}>
                        <div
                            className="row"
                            style={{
                            width: "-webkit-fill-available"
                        }}>

                            <div className="col-md-12"><br/><br/><br/></div>

                            <div className="col-md-2">
                                <img src="assets/images/brainstorming.svg" alt=""/><br/><br/>
                            </div>
                            <div className="col-md-10 text-justify features1">
                                <span className="heading">No Brainstorming!<br/>
                                    Just Exercising!</span><br/><br/>
                                <span
                                    className="paragraph"
                                    style={{
                                    fontSize: "17px"
                                }}>
                                    Our fitness coaches take a close look up to what will suit to reach your goal and prepare an appropriate plan for them. All you have to do is follow the routine. All our workouts are designed with a combination of aerobic and strength training. Each workout session brings you one step forward to our ultimate goal. You won’t need to be part of a long and complex thinking process. We will get the job done for you! All you need is to concentrate on your exercise. Just work to achieve the goals crafted for you and see the magic.
                                </span>
                            </div>

                            <div className="col-md-12"><br/><br/><br/>
                                <hr/><br/><br/></div>

                            <div className="col-md-2">
                                <img src="assets/images/Completely virtual.svg" alt=""/><br/><br/>
                            </div>
                            <div className="col-md-10 text-justify features1">
                                <span className="heading">Completely virtual</span><br/><br/>
                                <span
                                    className="paragraph"
                                    style={{
                                    fontSize: "17px"
                                }}>We
                                    have the most elite fitness coaches who are well trained to provide 100% online
                                    and virtual fitness training. We make your workout hassle-free by bringing you
                                    the most elite fitness coaches just for you right on your laptop screens. You
                                    don’t have to go out or compromise on your timings for long distant gym and
                                    fitness centers, anymore. Gone are the days when an online trainer guided you
                                    through a pre-recorded one-way video. Join Deevefit and experience your trainer
                                    guiding and correcting your mistakes in real time. All you need is a webcam
                                    through which you can connect to the trainer virtually and follow his steps.
                                </span>
                            </div>

                            <div className="col-md-12"><br/><br/>
                                <hr/><br/><br/></div>

                            <div className="col-md-2">
                                <img src="assets/images/no compalancey.svg" alt=""/><br/><br/><br/>
                            </div>
                            <div className="col-md-10 text-justify features1">
                                <span className="heading">No Complacency</span><br/><br/>
                                <span
                                    className="paragraph"
                                    style={{
                                    fontSize: "17px"
                                }}>Deevefit
                                    will help you push yourself to reach your fitness goals every single day without
                                    fail and will encourage you to do better every day. Our Elite fitness coaches
                                    not only focus on your workout but teaches you to push your limits and see
                                    yourself changing into healthier and stronger you.
                                </span><br/><br/><br/>
                            </div>

                            <div className="col-md-12"><br/><br/>
                                <hr/><br/><br/></div>

                            <div className="col-md-2">
                                <img src="assets/images/positive community.svg" alt=""/><br/><br/>
                            </div>
                            <div className="col-md-10 text-justify features1">
                                <span className="heading">Positive community</span><br/><br/>
                                <span
                                    className="paragraph"
                                    style={{
                                    fontSize: "17px"
                                }}>DEEVEFIT
                                    helps you to provide an environment where every single individual is motivated
                                    to work out to achieve their goals and where everybody is supportive towards
                                    each other. Becoming a part of our community, you will be inspired by working
                                    out with individuals who work hard and may have reached further goals. You will
                                    surely be motivated and inspired to work harder to achieve your own goals
                                    helping you fast track you towards better health. Independent of where you live,
                                    we help you find a community u feel comfortable with; share experiences,
                                    challenges, and success with and celebrate your achievements altogether.
                                </span><br/><br/>
                            </div>

                            <div className="col-md-12"><br/><br/>
                                <hr/><br/><br/></div>

                            <div className="col-md-2 col-xs-4">
                                <img src="assets/images/Personalized workout plan.svg" alt=""/><br/><br/>
                            </div>

                            <div className="col-md-10 text-justify features1">
                                <span className="heading">Personalized workout plan</span><br/><br/>
                                <span
                                    className="paragraph"
                                    style={{
                                    fontSize: "17px"
                                }}>It is not important how many hours you practice but how smartly you do it. Most people fail to understand the difference and hence, they make themselves fall into a dig of failure. And to recognize it one needs to have a good coach to train, supervise them and make them empowered to do more. At Deevefit, we care about your fitness goals and always work towards it. We help you focus on specific goals and workout sufficiently without wasting time
                                </span><br/><br/>
                            </div>

                        </div>
                    </div>*/}
      </div>
    );
  }
}

export default Features;
