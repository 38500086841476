import React, { Component } from "react";
import "./App.css";

class WhatWeOffer extends Component {
  render() {
    return (
      <div
        className="container-fluid"
        id="services"
        style={{ textAlign: "center" }}
      >
        <h1
          className="hd"
          style={{ fontSize: "3.5rem", margin: "0.5em 0", marginTop: "1.25em" }}
        >
          WHAT WE OFFER
        </h1>

        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "space-evenly",
            alignItems: "center",
          }}
        >
          {[
            "Fitness",
            "Yoga",
            "Nutrition Program",
            "Corporate Fitness",
            "Meal Prep",
          ].map((title, index) => (
            <div className="offer-card" key={index} style={{ padding: 0 }}>
              <img
                src={`assets/images/Service ${index + 1}.png`}
                alt={`Deevefit_${index}`}
                style={{ margin: 0 }}
              />
              <h1>{title}</h1>
            </div>
          ))}
        </div>
        {/* <div style={style.div}>
          <img
            style={style.img}
            src="assets/images/updated_how_it_works.png"
            alt=""
          />
        </div> */}
      </div>
    );
  }
}

export default WhatWeOffer;
