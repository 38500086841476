export const NutritionPoints = [
  "Dedicated coach",
  "Unlimited one-on-one consultation calls",
  "Unlimited one-on-one chats",
  "Custom diet plans based on your goals",
  "Access the deevefit exclusive content",
];

export const Nutrition = [
  {
    id: "price_1JbabhKkTyrBjV4Ly99gf8Ru",
    heading: "Nutrition",
    original_price: null,
    current_price: 299,
    months: 3,
    description: "Add 3 months Fitness pack for just",
    description_price: 100,
    discount: null,
    combo_plan: "price_1JbabgKkTyrBjV4L3irKu9cR",
    combo_plan_heading: "Nutrition + Fitness",
    combo_price: 399,
  },
  {
    id: "price_1JbabhKkTyrBjV4LTQX9nKSn",
    heading: "Nutrition",
    original_price: 439,
    current_price: 399,
    months: 6,
    description: "Add 6 months Fitness pack for just",
    description_price: 300,
    discount: 10,
    combo_plan: "price_1JbabgKkTyrBjV4LGcQHPVU5",
    combo_plan_heading: "Nutrition + Fitness",
    combo_price: 699,
  },
  {
    id: "price_1JbabgKkTyrBjV4Lmu9Ws1sB",
    heading: "Nutrition",
    original_price: 659,
    current_price: 549,
    months: 12,
    description: "Add 12 months Fitness pack for just",
    description_price: 650,
    discount: 20,
    combo_plan: "price_1JbabgKkTyrBjV4Lwy6ADbxI",
    combo_plan_heading: "Nutrition + Fitness",
    combo_price: 1199,
  },
];

export const FitnessPoints = [
  "Live small group or personal one on one",
  "focus training depending on the program",
  "The Coach would prepare the workout for the day",
  "Real-time monitoring of user exercise by Coach",
  "Attend 3 fitness classes for week",
];

export const FitnessPersonal = [
  {
    id: "price_1JbabgKkTyrBjV4LAm9gKpqr",
    heading: "Fitness Personal",
    current_price: 214.99,
    per_class: 17.99,
    months: 1,
  },
  {
    id: "price_1JbabgKkTyrBjV4Lx8PEk2MI",
    heading: "Fitness Personal",
    current_price: 539,
    per_class: 14.99,
    months: 3,
    description: "Add 3 months Nutrition pack for just",
    description_price: 180,
    combo_plan: "price_1JbabgKkTyrBjV4L3irKu9cR",
    combo_plan_heading: "Nutrition + Fitness",
    combo_price: 399,
  },
  {
    id: "price_1JbabgKkTyrBjV4LbTFHeGIF",
    heading: "Fitness Personal",
    current_price: 935,
    per_class: 12.99,
    months: 6,
    description: "Add 6 months Nutrition pack for just",
    description_price: 350,
    combo_plan: "price_1JbabgKkTyrBjV4LGcQHPVU5",
    combo_plan_heading: "Nutrition + Fitness",
    combo_price: 699,
  },
  {
    id: "price_1JbabgKkTyrBjV4LyVDKERCV",
    heading: "Fitness Personal",
    current_price: 1439,
    per_class: 6.99,
    months: 12,
    description: "Add 12 months Nutrition pack for just",
    description_price: 630,
    combo_plan: "price_1JbabgKkTyrBjV4Lwy6ADbxI",
    combo_plan_heading: "Nutrition + Fitness",
    combo_price: 1199,
  },
];
export const FitnessSmallGroup = [
  {
    id: "price_1JbabgKkTyrBjV4LLBoMOw3j",
    heading: "Fitness Small Group",
    current_price: 89,
    per_class: 6.99,
    months: 1,
    credits: 12,
  },
  {
    id: "price_1JbabgKkTyrBjV4L9DZki3vR",
    heading: "Fitness Small Group",
    current_price: 219,
    actual_price: 92,
    months: 3,
    credits: 36,
  },
  {
    id: "price_1JbabgKkTyrBjV4LiFqqwP2u",
    heading: "Fitness Small Group",
    current_price: 349,
    actual_price: 150,
    months: 6,
    discount: 20,

    free_weeks: 2,
    credits: 72,
  },
  {
    id: "price_1JbabgKkTyrBjV4LXFyNAM4R",
    heading: "Fitness Small Group",
    current_price: 569,
    actual_price: 150,
    months: 12,
    discount: 20,
    free_months: 1,
    credits: 144,
  },
];
export const NutritionFitnessPoints = [
  "Dedicated coach",
  "Unlimited one-on-one consultation calls",
  "Unlimited one-on-one chats",
  "Custom diet plans based on your goals",
  "Access the deevefit exclusive content",
  "Attend 3 fitness classes for week",
];

export const NutritionFitness = [
  {
    id: "price_1JbabgKkTyrBjV4L3irKu9cR",
    heading: "Nutrition + Fitness",
    current_price: 399,
    months: 3,
  },
  {
    id: "price_1JbabgKkTyrBjV4LGcQHPVU5",
    heading: "Nutrition + Fitness",
    current_price: 699,
    original_price: 769,
    months: 6,
    discount: 10,
    free_weeks: 2,
  },
  {
    id: "price_1JbabgKkTyrBjV4Lwy6ADbxI",
    heading: "Nutrition + Fitness",
    current_price: 1199,
    original_price: 1439,
    months: 12,
    discount: 20,
    free_months: 2,
  },
];

export const Yoga = [
  {
    id: "price_1JbabgKkTyrBjV4LSHJRsIBj",
    heading: "Yoga",
    current_price: 45,
    months: 1,
    per_class: 2,
    description: "5 times a week",
    time: "Monday to Friday",
  },
];
